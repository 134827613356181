import { useEffect, useState } from "react";
import ReactGA from "react-ga";

function useGaTracker() {
  useEffect(() => {
    ReactGA.initialize("UA-229963110-1");
  }, []);

  useEffect(() => {
    ReactGA.pageview("bania");
  });
}

export default useGaTracker;
