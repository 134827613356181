import React from "react";
import styled from "styled-components";

interface IProps {
  margin: string | number;
}

const SpacerContainer = styled.div<IProps>`
  display: flex;
  min-height: ${({ margin }) =>
    typeof margin === "string" ? margin : `${margin}px`};
`;

export default function Spacer(props: IProps) {
  return <SpacerContainer {...props} />;
}

Spacer.defaultProps = {
  margin: "1rem",
};
