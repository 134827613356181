export const SCREENS = {
  xsm: "410px",
  // => @media (min-width: 410px) { ... }

  vsm: "550px",
  // => @media (min-width: 550px) { ... }

  sm: "640px",
  // => @media (min-width: 640px) { ... }

  md: "768px",
  // => @media (min-width: 768px) { ... }

  lg: "1024px",
  // => @media (min-width: 1024px) { ... }

  xl: "1280px",
  // => @media (min-width: 1280px) { ... }

  xxl: "1536px",
  // => @media (min-width: 1536px) { ... }

  fhd: "1920px",
  // => @media (min-width: 1920px) { ... }
};
