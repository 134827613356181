import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { SCREENS } from "../assets/responsive";
import { Container } from "../components/styled/Container.styled";
import LinkContainer from "../components/LinkContainer";
import Spacer from "../components/Spacer";
import {
  Description,
  SubTitle,
  Title,
  DescriptionContainer,
} from "../components/styled/Text.styled";

import gsap from "gsap";
import { Expo, Power4 } from "gsap/src";
import ScrollTrigger from "gsap/ScrollTrigger";

import { portfolioData } from "../pages/Portfolio/portfolioData";

interface IProps {
  bg?: string;
}

const PortfolioContainer = styled(Container)`
  padding: 8rem 1rem;
  @media screen and (min-width: ${SCREENS.md}) {
    flex-direction: unset;
    align-items: flex-start;
    flex-wrap: wrap;
    /* gap: 100px 0; */
    justify-content: space-between;
  }
  .portfolio-link {
    width: 100%;
    position: relative;

    @media screen and (min-width: 1025px) and (max-width: 1100px) {
      &:nth-child(odd) {
        transform: translateY(-20%);
      }
    }
    @media screen and (min-width: 1100px) and (max-width: 1200px) {
      &:nth-child(odd) {
        transform: translateY(-25%);
      }
    }
    @media screen and (min-width: 1200px) and (max-width: 1300px) {
      &:nth-child(odd) {
        transform: translateY(-25%);
      }
    }
    @media screen and (min-width: 1301px) {
      &:nth-child(odd) {
        transform: translateY(-40%);
      }
    }
    @media screen and (min-width: ${SCREENS.lg}) {
      width: 47%;
    }
  }
`;

const PortfolioCard = styled.div<IProps>`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 50% 0 50% 0;

  background-color: ${(props) => props.theme.colors.dark};
  background: ${(props: IProps) => props.bg && `url(${props.bg})`};
  background-position-x: center;
  background-size: cover;
  box-shadow: 0px 0px 3px 2px rgba(26, 26, 26, 0.1);
  /* row-gap: 2rem; */
  margin-bottom: 1.5rem;
  cursor: pointer;
  transition: background-position-y 5000ms linear;

  &:hover {
    background-position-y: bottom;
    .description {
      opacity: 1;
    }
  }

  .description {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    padding: 2rem 2rem;
    color: white;
    text-decoration: none;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    transition: 400ms all ease-in-out;
    display: flex;
    align-items: flex-end;
    .content {
      padding-bottom: 3rem;
      max-height: 100%;
      h3 {
        margin-bottom: 1rem;
        font-size: 2rem;
      }
      p {
        line-height: 1.5em;
      }
      .tags {
        display: flex;
        margin-top: 1rem;
        gap: 0.5rem;
        flex-wrap: wrap;
        p {
          background: ${(props) => props.theme.colors.yellow};
          padding: 0.5rem 1rem;
          border-radius: 15px;
          color: ${(props) => props.theme.colors.black};
          font-weight: 500;
        }
      }
    }
  }
`;

export default function Portfolio() {
  const itemsRef = useRef<Array<HTMLElement>>([]);
  itemsRef.current = [];
  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (item: any) => {
    if (item) {
      itemsRef.current.push(item);
    }
  };

  const textRef = useRef(null);
  const q = gsap.utils.selector(textRef);

  useEffect(() => {
    gsap.from(q(".text-animate"), {
      y: "100%",
      opacity: 0,
      duration: 1.6,
      stagger: 0.22,
      ease: Expo.easeOut,
      scrollTrigger: {
        trigger: ".text-animate",
        start: "top 80%",
      },
    });

    itemsRef.current.map((item, index) => {
      console.log(index);
      gsap.from(item, {
        duration: 1.5,
        y: "10%",
        opacity: 0,
        delay: index == 0 ? 0.5 : 0,

        ease: Expo.easeOut,
        scrollTrigger: {
          trigger: item,
          start: index == 2 ? "top 60%" : "top 80%",
        },
      });
    });
  }, []);

  return (
    <PortfolioContainer>
      <DescriptionContainer ref={textRef} className="description-container">
        <Title className="text-animate">Portfolio</Title>
        <SubTitle className="text-animate">
          All Commercial Work, <br /> Selected Projects.
        </SubTitle>
        <Description className="text-animate">
          Take a look on a few projects that I have made. <br />
          Each of them was made with passion and desire to deliver as good as it
          can be.
          <br />
          From UX Design through Coding, everything done by myself.
        </Description>
        <Spacer margin="7rem" />
        <LinkContainer href="portfolio" className="text-animate">
          View all
        </LinkContainer>
      </DescriptionContainer>
      {portfolioData.slice(0, 3).map((portfolio) => (
        <Link
          className="portfolio-link"
          key={portfolio.id}
          to={`/portfolio/${portfolio.id}`}
          ref={addToRefs}
        >
          <PortfolioCard
            bg={portfolio.longImg ? portfolio.longImg : portfolio.heroImg}
          >
            <div className="description">
              <div className="content">
                <h3>{portfolio.id}</h3>
                <p>{portfolio.about}</p>
                <div className="tags">
                  {portfolio.tags.map((tag) => (
                    <p key={tag}>{tag}</p>
                  ))}
                </div>
              </div>
            </div>
          </PortfolioCard>
        </Link>
      ))}
    </PortfolioContainer>
  );
}
