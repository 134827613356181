import React, { FormEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import LinkContainer from "../components/LinkContainer";
import Spacer from "../components/Spacer";
import { Button } from "../components/styled/Button.styled";
import { Container } from "../components/styled/Container.styled";
import {
  Title,
  SubTitle,
  Description,
  DescriptionContainer,
} from "../components/styled/Text.styled";
import Wrapper from "../components/Wrapper";

import gsap from "gsap";
import { Expo, Power4 } from "gsap/src";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useForm, ValidationError } from "@formspree/react";
import { toast } from "react-toastify";

const CustomContainer = styled(Container)`
  flex-direction: row;
  flex-wrap: wrap;
`;

const FormWrapper = styled.div`
  flex-grow: 1;

  form {
    display: flex;
    flex-direction: column;
  }
`;

const FormGroup = styled.div`
  input {
    transition: 0.2s all ease-in-out;
  }
  input,
  textarea {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    margin-bottom: 1rem;
    background: ${(props) => props.theme.colors.dark};
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey};
    padding-bottom: 4px;
    color: ${(props) => props.theme.colors.grey};
    font-size: 1.3rem;

    &:focus-visible {
      padding-bottom: 0;
      border-bottom: 5px solid ${(props) => props.theme.colors.yellow};

      outline: none;
      &::placeholder {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
  textarea {
    margin-top: 7px;
    width: 100%;
    resize: vertical;
    height: 150px;
    transition: 0.2s border-bottom-width ease-in-out;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #2e2e35;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #9e9e9e;
      border-radius: 100px;
    }
  }
`;

const SendButton = styled(Button)`
  margin-top: 10px;
  margin-left: auto;
  padding: 0.75rem 2rem;
`;

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // const handleSubmit = (event: FormEvent) => {
  //   event.preventDefault();
  //   setMessage("");
  //   setEmail("");
  //   setName("");
  //   alert("Message has been sent");
  // };

  const textRef = useRef(null);
  const q = gsap.utils.selector(textRef);
  const contactRef = useRef(null);
  const cq = gsap.utils.selector(contactRef);

  useEffect(() => {
    gsap.from(q(".contact-animate"), {
      y: "100%",
      opacity: 0,
      duration: 1.6,
      stagger: 0.22,
      ease: Expo.easeOut,
      scrollTrigger: {
        trigger: ".contact-animate",
        start: "top 80%",
      },
    });

    gsap.from(cq(".form-animate"), {
      x: "100%",
      opacity: 0,
      duration: 1.6,
      stagger: 0.22,
      delay: 0.5,
      ease: Expo.easeOut,
      scrollTrigger: {
        trigger: ".form-animate",
        start: "top 80%",
      },
    });
  }, []);

  const [state, handleSubmit] = useForm("mqknnewb");

  if (state.succeeded) {
    toast.success("Email has been sent!", {
      toastId: "success1",
    });
  }

  return (
    <Wrapper center bgcolor="dark">
      <CustomContainer>
        <DescriptionContainer ref={textRef}>
          <Title className="contact-animate">Contact</Title>
          <SubTitle className="contact-animate">Let's Talk.</SubTitle>
          <Description className="contact-animate">
            Are You interested in my services? <br />
            Or maybe You would like to colaborate with me? <br />
            Leave me a message, and I will get back to You! <br />
          </Description>
          <Spacer margin="8rem" />
          <LinkContainer
            className="contact-animate"
            href="mailto:dominik.koralewski@gmail.com"
          >
            dominik.koralewski@gmail.com
          </LinkContainer>
        </DescriptionContainer>
        <FormWrapper ref={contactRef}>
          <form onSubmit={handleSubmit}>
            <FormGroup className="form-animate">
              <input type="text" id="name" name="name" placeholder="Name" />
              <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
              />
            </FormGroup>
            <FormGroup className="form-animate">
              <input id="email" type="email" name="email" placeholder="Email" />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </FormGroup>
            <FormGroup className="form-animate">
              <textarea
                id="message"
                name="message"
                rows={100}
                placeholder="Message"
              />
            </FormGroup>

            <SendButton
              className="form-animate"
              color="yellow"
              type="submit"
              disabled={state.submitting}
            >
              Send
            </SendButton>
          </form>
        </FormWrapper>
      </CustomContainer>
    </Wrapper>
  );
}
