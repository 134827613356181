import styled from "styled-components";
import { SCREENS } from "../../assets/responsive";

interface ButtonProps {
  color?: string;
}

export const Button = styled.button<ButtonProps>`
  background-color: ${(props) =>
    props.color === "yellow"
      ? props.theme.colors.yellow
      : props.color === "black"
      ? props.theme.colors.black
      : props.theme.colors.white};
  color: ${(props) =>
    props.color === "yellow"
      ? props.theme.colors.black
      : props.color === "black"
      ? props.theme.colors.white
      : props.theme.colors.black};
  padding: 0.7rem 1.5rem;
  font-size: 15px;
  font-weight: 700;
  border-radius: 10px;
  border: 0;
  cursor: pointer;
  /* transition: box-shadow 1s ease-in-out; */
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: ${(props) =>
    props.color === "yellow"
      ? "0px 0px 30px 0px rgba(229, 171, 74, 0.1)"
      : props.color === "black"
      ? "0px 0px 30px 0px rgba(0, 0, 0, 0.1)"
      : "0px 0px 30px 0px rgba(255, 255, 255, 0.1)"};

  &:hover {
    /* border-radius: 15px; */

    background-color: ${(props) =>
      props.color === "yellow"
        ? props.theme.colors.yellow0
        : props.color === "black"
        ? props.theme.colors.black
        : props.theme.colors.white};

    box-shadow: ${(props) =>
      props.color === "yellow"
        ? "0px 0px 30px 0px rgba(229, 171, 74, 0.25)"
        : props.color === "black"
        ? "0px 0px 30px 0px rgba(0, 0, 0, 0.25)"
        : "0px 0px 30px 0px rgba(255, 255, 255, 0.25)"};
  }
  @media screen and (min-width: ${SCREENS.md}) {
    font-size: 15px;
    padding: 1rem 2.2rem;
  }

  @media screen and (min-width: ${SCREENS.lg}) {
    font-size: 17px;
    padding: 1.2rem 2.4rem;
  }
  @media screen and (min-width: ${SCREENS.xl}) {
    font-size: 20px;
    padding: 1.3rem 2.6rem;
  }
`;
