import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import styled, { ThemeContext, ThemeProvider } from "styled-components";
import Theme from "./assets/globalStyles";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./layout/Footer";
import Navbar from "./layout/Navbar";
import HomePage from "./pages/HomePage";
import Portfolio from "./pages/Portfolio";
import Single from "./pages/Portfolio/Single";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContactPage from "./pages/ContactPage";
import NotFound from "./pages/NotFound";
import useGaTracker from "./hooks/useGaTracker";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  color: ${(props) => props.theme.colors.white};
`;

function App() {
  useGaTracker();

  return (
    <Theme>
      <Router>
        <ScrollToTop />
        <AppContainer>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/portfolio/:id" element={<Single />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </AppContainer>
      </Router>
      <ToastContainer />
    </Theme>
  );
}

export default App;
