import React from "react";
import styled from "styled-components";
import { SCREENS } from "../assets/responsive";
import {
  FaFacebookSquare,
  FaLinkedin,
  FaMailBulk,
  FaInstagram,
  FaGithubSquare,
} from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { Link } from "react-router-dom";

const FooterContainer = styled.div`
  padding: 3rem 2rem;
  color: ${(props) => props.theme.colors.white};
  width: 100%;
  max-width: ${SCREENS.xxl};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  @media screen and (min-width: ${SCREENS.md}) {
    flex-direction: row;
  }
`;

const LogoContainer = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const CopyRightContainer = styled.div`
  color: ${(props) => props.theme.colors.grey};
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 25px;
  gap: 10px;
  a {
    color: ${(props) => props.theme.colors.white};
  }
`;

export default function Footer() {
  return (
    <FooterContainer>
      <LogoContainer>
        <Link to="/">koralesky</Link>
      </LogoContainer>
      <CopyRightContainer>2022 All rights reserved.</CopyRightContainer>
      <IconsContainer>
        <a href="https://github.com/koralesky" target="_blank">
          <FaGithubSquare />
        </a>
        {/* <a
          href="https://www.linkedin.com/in/dominik-k-432529206/"
          target="_blank"
        >
          <FaLinkedin />
        </a> */}
        <a href="mailto:dominik.koralewski@gmail.com" target="_blank">
          <FaMailBulk />
        </a>
      </IconsContainer>
    </FooterContainer>
  );
}
