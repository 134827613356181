import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Button } from "../components/styled/Button.styled";
import { SCREENS } from "../assets/responsive";
import { Container } from "../components/styled/Container.styled";
import DotsImg from "../assets/img/dots.png";
import Guy from "../assets/img/dominik.png";
import Blob from "../assets/img/blob4.svg";
import gsap from "gsap";
import { Expo, Power4 } from "gsap/src";
import { CSSRulePlugin } from "gsap/src/all";
import { Link } from "react-router-dom";

const HeroContainer = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-height: 1080.98px) and (min-width: ${SCREENS.lg}) {
    min-height: 100vh;
    max-height: 100vh;
  }
  @media screen and (min-height: 1081px) and (min-width: ${SCREENS.lg}) {
    min-height: 70vh;
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    flex-direction: column;
  }
  .asd {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    opacity: 60%;
    background: ${(props) => props.theme.colors.yellow};
    filter: blur(130px);
  }
  .asd2 {
    position: absolute;
    bottom: 10%;
    left: 0;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    opacity: 60%;
    background: ${(props) => props.theme.colors.yellow};
    filter: blur(130px);
  }
`;

const LeftContainer = styled.div`
  /* background: blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
  @media screen and (max-width: ${SCREENS.lg}) {
    padding-top: 200px;
  }
`;

const HeroHead = styled.div`
  font-weight: 700;
  margin-bottom: 1.5rem;
  font-size: 60px;
  /* font-family: "Open Sans", sans-serif; */
  color: ${(props) => props.theme.colors.white};
  line-height: 1.1em;
  position: relative;

  .clone {
    position: absolute;
    top: 0;
    color: ${(props) => props.theme.colors.white};
    .first-row {
      display: flex;
      .white {
        color: ${(props) => props.theme.colors.white};
        margin-left: 2rem;
      }
      .black {
        color: ${(props) => props.theme.colors.black};
      }
    }

    .bottom {
      text-decoration: underline;
      text-decoration-color: ${(props) => props.theme.colors.yellow2};
      text-decoration-thickness: 10px;
    }
  }

  .first-word {
    color: ${(props) => props.theme.colors.white};
    position: relative;
    &:before {
      content: "";
      right: -10px;
      z-index: -1;
      bottom: 0;
      position: absolute;
      border-radius: 0 0 10px 0;
      height: 1000px;
      width: 100vw;
      background: ${(props) => props.theme.colors.white};
    }
  }
  .second-word {
    color: ${(props) => props.theme.colors.black};
  }
  .last-word {
    font-weight: 900;
    color: ${(props) => props.theme.colors.black};
  }

  @media screen and (min-width: ${SCREENS.md}) {
    font-size: 80px;
  }

  @media screen and (min-width: ${SCREENS.lg}) {
    font-size: 100px;
    .first-word {
      &:before {
        right: -20px;
      }
    }
  }
  @media screen and (min-width: ${SCREENS.xl}) {
    font-size: 120px;
  }
`;

const HeroCta = styled.div`
  @media screen and (max-width: ${SCREENS.lg}) {
    margin-bottom: 13px;
  }
  a {
    display: inline-flex;
    text-decoration: none;
  }
`;

const HeroLink = styled.span`
  font-size: 15px;
  font-weight: 700;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.white};
  margin-left: 20px;
  @media screen and (min-width: ${SCREENS.md}) {
    font-size: 15px;
  }

  @media screen and (min-width: ${SCREENS.lg}) {
    font-size: 17px;
  }
  @media screen and (min-width: ${SCREENS.xl}) {
    font-size: 20px;
  }
`;

const RightContainer = styled.div`
  /* background: green; */
  flex-grow: 1;
  position: relative;
  bottom: 0;
  z-index: 5;
`;

const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (min-width: ${SCREENS.lg}) {
    height: 100%;
  }
  #me-img {
    z-index: 60;
    @media screen and (max-width: ${SCREENS.lg}) {
      max-width: 90vw;
      height: auto;
      margin-right: -40px;
    }
    @media screen and (min-width: ${SCREENS.lg}) {
      height: 100%;
      width: auto;
    }
  }
  #blob {
    position: absolute;
    z-index: 59;
    left: -5%;
    top: 10%;
  }
`;

const Dots = styled.img`
  position: absolute;
  bottom: 15%;
  left: -5%;
  z-index: 1;
`;

export default function Hero() {
  const textRef = useRef(null);
  const q = gsap.utils.selector(textRef);
  useEffect(() => {
    gsap.from(q(".animate"), {
      y: "100%",
      stagger: 0.25,
      delay: 1,
      opacity: 0,
      duration: 1.6,
      // ease: Power2.easeInOut,
      ease: Expo.easeOut,
    });
  }, []);
  return (
    <Container>
      <HeroContainer ref={textRef}>
        <LeftContainer>
          <HeroHead>
            <span className="first-word">Front</span>{" "}
            <span className="second-word">End</span> <br />{" "}
            <span className="last-word">Developer</span>
            <div className="clone">
              <div className="first-row">
                <div className="animate black">Front</div>
                <div className="animate white">End</div>
              </div>

              <div className="animate bottom">Developer</div>
            </div>
          </HeroHead>
          <HeroCta>
            <Link to="/contact" className="animate">
              <Button color="yellow">Contact me</Button>
            </Link>
            <Link to="/portfolio" className="animate">
              <HeroLink>What I Did?</HeroLink>
            </Link>
          </HeroCta>
        </LeftContainer>
        <RightContainer>
          <ImgContainer>
            <img id="me-img" className="animate" src={Guy} />
            <img id="blob" src={Blob} className="animate" />
          </ImgContainer>
          <Dots src={DotsImg} className="animate" />
        </RightContainer>
      </HeroContainer>
    </Container>
  );
}
