import TibiaSwap from "../../assets/Images/mockups/tibiaswap.jpg";
import TibiaSwapLong from "../../assets/Images/mockups/tibiaswaphome.jpg";
import IMD from "../../assets/Images/mockups/metaduckbills.jpg";
import IMDLong from "../../assets/Images/mockups/imdhome.jpg";
import SoalasLong from "../../assets/Images/mockups/soalashome.jpg";
import Soalas from "../../assets/Images/mockups/soalas.jpg";
import MpLogo from "../../assets/Images/mockups/mp-logo.jpg";
import MpLogoShowcase from "../../assets/Images/mockups/mp-logo-showcase.jpg";
import MpWebsite from "../../assets/Images/mockups/mp-website.jpg";
import MpBusinessCard from "../../assets/Images/mockups/mp-business-card.jpg";
import RemoteBusiness from "../../assets/Images/mockups/remote-business.jpg";
import RemoteLanyard from "../../assets/Images/mockups/remote-lanyard.jpg";
import RemoteLanyard2 from "../../assets/Images/mockups/remote-lanyard2.jpg";
import RemoteLogo from "../../assets/Images/mockups/remote-logo.jpg";
import RemotePen from "../../assets/Images/mockups/remote-pen.jpg";
import MozlyLogo from "../../assets/Images/mockups/mozly-logo-mockup.jpg";
import MozlyLogoShowcase from "../../assets/Images/mockups/mozly-logo-showcase.jpg";
import MozlyBusinessCard from "../../assets/Images/mockups/mozly-business-card.jpg";
import Tatooroom from "../../assets/Images/mockups/tatooroom.jpg";
import MptwLogo from "../../assets/Images/mockups/mptw-logo.jpg";
import MptwLogo2 from "../../assets/Images/mockups/mptw-logo-2.jpg";
import MptwLogoShowcase from "../../assets/Images/mockups/mptw-logo-showcase.jpg";
import MptwBusinessCard from "../../assets/Images/mockups/mptw-business-card.jpg";
import MptwWebsite from "../../assets/Images/mockups/mptw-website.jpg";
import Kancelaria from "../../assets/Images/mockups/kancelaria.jpg";

export interface IPortfolio {
  id: string;
  title: string;
  services: string[];
  technologies: string[];
  about: string;
  heroImg: string;
  description: string;
  challange?: string;
  solutions?: string[];
  website?: string;
  website2?: string;
  twitter?: string;
  discord?: string;
  markets?: string[];
  fb?: string;
  tags: string[];
  longImg?: string;
  images?: string[];
}

export const portfolioData: IPortfolio[] = [
  {
    id: "TibiaSwap",
    title: "TibiaSwap <br/> Website",
    services: [
      "UI/UX, Front-End",
      "Graphic Design",
      "Web3.js Integration",
      "NFT Mint",
    ],
    technologies: ["React, Redux", "Solana Web3.js", "Styled Components"],
    about:
      "Refactor 2000s Website for Play2Earn Tibia Game Server into modern Single Page Application using React, and integrate it with Solana blockchain.",
    heroImg: TibiaSwap,
    description:
      "TibiaSwap is a Play2Earn server for a game created in the 90s. The aim of the project was to transform the nostalgic, outdated version of the website from those years to the latest standards without changing oldschool style and to integrate with the Solana cryptocurrency blockchain, so playars could sell in-game items for cryptocurrencies.",
    challange:
      "Refactor technologically old, non-responsive website into responsive Single Page Application, and connect with Solana blockchain, to enable possibility to earn cryptocurrency playing, trading items as NFTS, selling them for tokens, etc.",
    solutions: [
      "Develop Single Page Application using React.js without changing oldschool style of old website",
      "Enable login and register using Facebook and Google Authentication",
      "Handle SPL-Token wallets, and pair them with account",
      "Create NFT Mint page for the Beta Pass.",
      "Burn NFT to activate Beta Pass",
      "Handle API requests to create account, character, change password, etc.",
      "Design Logo, and graphics for marketing purposes",
    ],
    tags: ["Web Development", "Logo Design", "UI/UX", "Web3.js"],
    longImg: TibiaSwapLong,
    website: "https://tibiaswap.com",
    discord: "https://tibiaswap.com",
    twitter: "https://twitter.com/tibia_swap",
  },

  {
    id: "Meta Duckbills",
    title: "Interdimensional Meta Duckbills",
    services: [
      "UI/UX, Front-End",
      "Graphic Design",
      "Web3.js Integration",
      "NFT Mint",
    ],
    technologies: [
      "React, Next.js",
      "Solana Web3.js",
      "Styled Components",
      "Tailwind",
    ],
    about:
      "Design and code Next.js apllication for NFT Project called Meta Duckbills. Meta Duckbills is NFT web-browser idle game, and casino.",
    heroImg: IMD,
    description:
      "MetaDuckbills is a web-browser NFT idle Game and also a Casino. Users can Mint MetaDuckbills which allow them to participate in missions, where they can earn $PLAT SPL Tokens.   ",
    challange:
      "Create design system and then create landing page and website using it. Landing page should be a information about what is this project about, and when it launches. Application is the place, where all the utilites are. Utilites for the applications are: Dashboard, Auctions & Raffles, Staking, Game, Account Settings and Market. Apllication should be integrated with Solana blockchain.",
    solutions: [
      "Create minimalistic and eye-pleasing design system using Figma",
      "Design landing page and application using Figma",
      "Code designed layout into SPA using Next.js",
      "Integrate application with Solana blockchain using Solana web3.js",
    ],
    tags: ["Web Development", "UI/UX", "Web3.js"],
    longImg: IMDLong,
    website: "https://metaduckbills.vercel.app",
    website2: "https://metaduckbills.com",
    discord: "https://discord.gg/hX92vAAg4R",
    twitter: "https://twitter.com/MetaDuckbills",
  },
  {
    id: "Soalas",
    title: "Soalas NFT",
    services: [
      "UI/UX, Front-End",
      "Graphic Design",
      "Web3.js Integration",
      "NFT Mint",
    ],
    technologies: ["React.js", "Solana Web3.js", "Styled Components"],
    about:
      "NFT Collection. Create landing page and Minting Page for NFT Collection called Soalas. More than 2000 NFT's sold in under 1 hour after launch.",
    heroImg: Soalas,
    description:
      "Soalas NFT is a collection of 3000 unique auto generated Koalas on Solana blockchain. Website was design the way that it looks like a Solana native project. At the moment of launch there was more than 1.500 users at once using website to mint their Soala.",
    challange:
      "Create website that is going to look like a native Solana project, integrate it with Solana blockchain, and make it possible to handle thousands of users in the same time.",
    solutions: [
      "Create minimalistic and eye-pleasing design system using Figma",
      "Design landing page and application using Figma",
      "Code designed layout into SPA using React.js",
      "Integrate application with Solana blockchain using Solana web3.js",
      "Host project on AWS S3 Bucket, so it can handle many users at once",
    ],
    tags: ["Web Development", "UI/UX", "Web3.js"],
    longImg: SoalasLong,
    website: "https://soalas.netlify.app",
    markets: [
      "https://solsea.io/collection/6166f44e306d516194a90080",
      "https://magiceden.io/marketplace?collection_symbol=soalas_nft&activeTab=items",
    ],
  },
  {
    id: "Komornik Bielany",
    title: "Komornik Monika Piątkowska",
    services: [
      "UI/UX, Front-End",
      "Graphic Design",
      "Logo Design",
      "Business Cards",
    ],
    technologies: [
      "PHP, HTML, CSS, JS",
      "Wordpress",
      "Adobe Photoshop, Illustrator",
    ],
    about:
      "Design Logo, Business Cards and Website for Baliff based in Wrocław.",
    heroImg: MpLogo,
    description:
      "Komornik Bielany is a Baliff located in Wrocław. Goal for the project was to create website, logo and business cards in a elegant and professional style. Logo contains M and P letters, which are clients first letters of name and surname.",
    tags: ["Web Development", "UI/UX", "Logo Design", "Print Design"],
    website: "https://komornikbielany.pl",
    images: [MpBusinessCard, MpWebsite, MpLogoShowcase],
  },
  {
    id: "Tattooroom",
    title: "Tattooroom",
    services: ["Graphic Design", "Web Development"],
    technologies: ["Wordpress", "HTML, CSS, JS, PHP"],
    about: "Design and code website for a tattoo artist.",
    website: "https://tattooroom.eu",
    heroImg: Tatooroom,
    description:
      "Goal for this project was to create easy to manage website portfolio for a tattoo artist based in Wrocław, Poland. Outcome is a wordpress website, that contains description of an artist, gallery of his works, and connection with Facebook API, to fetch clients testimonials.",
    tags: ["Web Development"],
  },
  {
    id: "Remote Codes",
    title: "Remote Codes",
    services: [
      "Graphic Design",
      "Logo Design",
      "Business Cards",
      "Print Design",
    ],
    technologies: ["Adobe Photoshop", "Adobe Illustrator"],
    about: "Design Logo, Business Cards, Pens and Lanyards.",
    heroImg: RemoteBusiness,
    description:
      "The shape of the logo alludes to web development, exactly to HTML tags '</>'. Beside logo, I've created design book, that is including colors and fonts. Basing on that design book I've designed business cards, lanyards and pens.",
    tags: ["Logo Design", "Print Design"],
    images: [
      RemoteLogo,
      RemoteLanyard,
      RemotePen,
      RemoteLanyard2,
      RemoteBusiness,
    ],
  },
  {
    id: "Mozly",
    title: "Mozly",
    services: [
      "Graphic Design",
      "Logo Design",
      "Business Cards",
      "Print Design",
    ],
    technologies: ["Adobe Photoshop", "Adobe Illustrator"],
    about: "Design Logo and Business Cards.",
    heroImg: MozlyLogo,
    description:
      "Conecpt of creating logo was to design something modern. Outcome is a letters M and C layed on a isometric cube. Project also includes business card.",
    tags: ["Logo Design", "Print Design"],
    images: [MozlyLogoShowcase, MozlyBusinessCard, MozlyLogo],
  },
  {
    id: "Mój Projekt Twoje Wnętrze",
    title: "Mój Projekt Twoje Wnętrze",
    services: ["Logo Design", "Print Design"],
    technologies: ["Adobe Photoshop", "Adobe Illustrator"],
    about: "Design logo and business cards for a interior Designer.",
    heroImg: MptwBusinessCard,
    description:
      "The goal of the project was to create a logo and a business card for interior Designer. Logo contains a chair in an isometric cube which represents room.  ",
    tags: ["Logo Design", "Print Design"],
    images: [MptwLogo2, MptwLogoShowcase, MptwBusinessCard, MptwWebsite],
  },
  {
    id: "Law Firm",
    title: "Law Firm",
    services: ["Web Development"],
    technologies: ["HTML", "CSS", "JS"],
    about: "Website prototype for a Law Firm.",
    website: "https://rozwody.kancelaria-zkz.pl/",
    website2: "https://kancelaria-prawna.netlify.app/",
    heroImg: Kancelaria,
    description: "",
    tags: ["Web Development"],
  },
];
