import React from "react";
import styled from "styled-components";
import NavbarSticky from "../layout/NavbarSticky";

const NotFoundContainer = styled.div`
  font-size: 3rem;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
`;

export default function NotFound() {
  return (
    <>
      <NavbarSticky />
      <NotFoundContainer>404 Page Not Found</NotFoundContainer>
    </>
  );
}
