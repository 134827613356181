import React from "react";
import Contact from "../layout/Contact";
import NavbarSticky from "../layout/NavbarSticky";

function ContactPage() {
  return (
    <>
      <NavbarSticky />
      <Contact />
    </>
  );
}

export default ContactPage;
