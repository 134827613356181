import styled from "styled-components";
import { SCREENS } from "../../assets/responsive";

export const Title = styled.h1`
  font-size: 1.1rem;
  letter-spacing: 1.5px;
  position: relative;
  padding-left: 20px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.grey};
  display: block;
  margin-bottom: 1rem;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 12px;
    height: 2px;
    background: ${(props) => props.theme.colors.grey};
  }
`;

export const SubTitle = styled.h2`
  font-size: 2.3rem;
  margin-bottom: 1.5rem;
  color: ${(props) => props.theme.colors.white};
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  @media screen and (min-width: ${SCREENS.md}) {
    width: 45%;
  }
`;

export const Description = styled.p`
  color: ${(props) => props.theme.colors.grey};
  line-height: 1.6rem;
`;
