import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { SCREENS } from "../assets/responsive";
import Menu from "../components/Menu";
import gsap from "gsap";
import { Expo, Power4 } from "gsap/src";
import { Link } from "react-router-dom";

const NavbarContainer = styled.nav`
  width: 100%;
  max-width: ${SCREENS.xxl};
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  z-index: 9999;
  margin-bottom: 2rem;
`;

const LogoContainer = styled.div`
  font-weight: 700;
  font-size: 17px;
  color: ${(props) => props.theme.colors.black};
  background: ${(props) => props.theme.colors.white};
  z-index: 999;
  padding-right: 5.6rem;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  margin-right: 1rem;
  position: relative;
  /* border-radius: 0 0 10px 0; */
  a {
    text-decoration: none;
    color: inherit;
  }
  &::before {
    content: "";
    position: absolute;
    left: -100vw;
    top: 0;
    width: 100vw;
    height: 100%;
    background: ${(props) => props.theme.colors.white};
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% + 100vw);
    transform: translateY(100%);
    height: 1rem;
    background: ${(props) => props.theme.colors.white};
    border-radius: 0 0 10px 0;
  }

  @media screen and (min-width: ${SCREENS.md}) {
    padding-right: 8.1rem;
    margin-right: 4.9rem;
    font-size: 20px;
  }
  @media screen and (min-width: ${SCREENS.xl}) {
    padding-right: 14rem;
    font-size: 25px;
    margin-right: 1rem;
  }
`;

const LinksContainer = styled.ul`
  margin-top: auto;
  margin-bottom: auto;
  color: ${(props) => props.theme.colors.white};
`;

const RightContainer = styled.div`
  width: 30px;
`;

export default function NavbarSticky() {
  const animationRef = useRef(null);
  const q = gsap.utils.selector(animationRef);
  useEffect(() => {
    gsap.from(q(".animate"), {
      opacity: 0,
      duration: 1,
      // ease: Power2.easeInOut,
      ease: Expo.easeOut,
    });
  }, []);
  return (
    <NavbarContainer ref={animationRef}>
      <LogoContainer>
        <Link to="/">koralesky</Link>
      </LogoContainer>
      <LinksContainer>
        <Menu />
      </LinksContainer>
      <RightContainer />
    </NavbarContainer>
  );
}
