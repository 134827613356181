import React from "react";
import styled from "styled-components";
import Hero from "../layout/Hero";

import Icons from "../layout/Icons";
import { SCREENS } from "../assets/responsive";
import Services from "../layout/Services";
import Portfolio from "../layout/Portfolio";
import Contact from "../layout/Contact";
import Navbar from "../layout/Navbar";

export const HomePageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function HomePage() {
  return (
    <>
      <Navbar />
      <HomePageContainer>
        <Hero />
        <Icons />
        <Services />
        <Portfolio />
        <Contact />
      </HomePageContainer>
    </>
  );
}
