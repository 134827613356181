export default {
  bmBurgerButton: {
    position: "absolute",
    width: "40px",
    height: "30px",
    right: "30px",
    top: "30px",
  },
  bmBurgerBars: {
    background: "#f7b141",
  },
  bmBurgerBarsHover: {
    background: "#f7b141",
  },
  bmCrossButton: {
    height: "34px",
    width: "34px",
    top: "20px",
    right: "20px",
  },
  bmCross: {
    background: "#f7b141",
    height: "30px",
  },
  bmMenuWrap: {
    position: "fixed",
    width: "60%",
    height: "100%",
    top: "0px",
  },
  bmMenu: {
    background: "#373a47",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.2)",
    width: "100vw",
    top: "0",
    left: "0",
  },
};
