import styled from "styled-components";
import React from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";

export const LContainer = styled(Link)`
  color: ${(props) => props.theme.colors.yellow};

  text-decoration: underline;
  font-weight: 500;
  font-size: 1.1rem;
  letter-spacing: 0.9px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-left: 30px;
    font-size: 1.5rem;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    color: ${(props) => props.theme.colors.yellow1};
    svg {
      margin-left: 37px;
    }
  }
`;

interface IProps {
  children: string;
  href: string;
  className?: string;
}

export default function LinkContainer(props: IProps) {
  return (
    <LContainer to={props.href} className={props.className}>
      {props.children}
      <BiRightArrowAlt />
    </LContainer>
  );
}
