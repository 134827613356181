import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { SCREENS } from "../../assets/responsive";
import { PortfolioContainer } from "../../components/styled/Portfolio.styled";
import NavbarSticky from "../../layout/NavbarSticky";
import TibiaSwap from "../../assets/Images/mockups/tibiaswap.png";
import IMD from "../../assets/Images/mockups/metaduckbills.png";
import { IPortfolio, portfolioData } from "./portfolioData";
import gsap from "gsap";
import { Expo, Power4 } from "gsap/src";
import ScrollTrigger from "gsap/ScrollTrigger";

interface IProps {
  bg?: string;
  isLoading?: boolean;
}

const PortfolioWrapper = styled(PortfolioContainer)<IProps>`
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h1 {
    font-size: 4rem;
    margin-bottom: 4rem;
  }
  .filter {
    display: flex;
    ul {
      list-style: none;
      margin-bottom: 4rem;
      display: inline-flex;
      overflow-x: auto;

      li {
        display: inline-flex;
        margin-right: 2rem;
        font-size: 1.3rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          color: ${(props) => props.theme.colors.yellow};
        }
      }
    }
  }
`;

const LinkWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 3rem;
  }
  @media screen and (min-width: ${SCREENS.lg}) {
    width: calc(50% - 2.5rem);
  }
  .tags {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
    color: ${(props) => props.theme.colors.grey};
  }
`;

const PortfolioLink = styled(Link)<IProps>`
  background: ${(props) => props.theme.colors.black};
  width: 100%;
  min-height: 300px;
  aspect-ratio: 1;
  box-shadow: 0px 0px 20px 4px rgba(26, 26, 26, 0.3);
  transition: all 300ms ease-in-out;
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
  @media screen and (min-width: ${SCREENS.sm}) {
    aspect-ratio: unset;
    height: 500px;
  }

  &:hover {
    &::after {
      transform: scale(1.4);
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: green;
    z-index: 5;
    background: ${(props: IProps) => props.bg && `url(${props.bg})`};
    background-size: cover;
    transform: scale(1.2);
    transition: all 300ms ease-in-out;
    background-position: center center;
  }
  div {
    position: relative;
    color: ${(props) => props.theme.colors.black};
    z-index: 10;
  }

  &:hover {
    box-shadow: 0px 0px 20px 4px rgba(26, 26, 26, 1);
  }
`;

const Portfolios = styled.div<IProps>`
  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
  gap: 5rem;
  justify-content: space-between;
  transition: all 200ms ease-in-out;
`;

function Portfolio() {
  const location = useLocation();
  const [portfolios, setPortfolios] = useState<undefined | IPortfolio[]>(
    undefined
  );
  const itemsRef = useRef<Array<HTMLElement>>([]);
  itemsRef.current = [];
  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (item: any) => {
    if (item) {
      itemsRef.current.push(item);
    }
  };

  useEffect(() => {
    setPortfolios(portfolioData);
  }, []);

  const filter = (toFind: string) => {
    if (toFind === "All") {
      setPortfolios(portfolioData);
    } else {
      setPortfolios(
        portfolioData?.filter((el) => el.tags.indexOf(toFind) > -1)
      );
    }
  };

  const animateRef = useRef(null);
  const q = gsap.utils.selector(animateRef);

  useEffect(() => {
    setTimeout(() => {}, 1000);
    gsap.from(q(".animate"), {
      y: "100%",
      opacity: 0,
      duration: 1.6,
      ease: Expo.easeOut,
    });
  }, []);

  useEffect(() => {
    itemsRef.current.map((item) => {
      gsap.from(item, {
        duration: 1.5,
        opacity: 0,
        ease: Expo.easeOut,
      });
    });
  }, [portfolios]);

  if (!portfolios) {
    return (
      <>
        <NavbarSticky />
      </>
    );
  }
  return (
    <>
      <NavbarSticky />
      <PortfolioWrapper ref={animateRef}>
        <h1 className="animate">Portfolio</h1>
        <div className="filter ">
          <ul>
            <li className="animate" onClick={() => filter("All")}>
              All
            </li>
            <li className="animate" onClick={() => filter("Web Development")}>
              Web Development
            </li>
            <li className="animate" onClick={() => filter("UI/UX")}>
              UI/UX
            </li>
            <li className="animate" onClick={() => filter("Logo Design")}>
              Logo Design
            </li>
            <li className="animate" onClick={() => filter("Print Design")}>
              Print Design
            </li>
          </ul>
        </div>

        <Portfolios>
          {portfolios.map((portfolio) => (
            <LinkWrapper ref={addToRefs} key={portfolio.id}>
              <PortfolioLink
                to={`/portfolio/${portfolio.id}`}
                bg={portfolio.heroImg}
              ></PortfolioLink>
              <div className="tags">
                {portfolio.tags.map((tag) => (
                  <p key={tag}>{tag}</p>
                ))}
              </div>
              <h2>
                <Link to={`/portfolio/${portfolio.id}`}>{portfolio.id}</Link>
              </h2>
            </LinkWrapper>
          ))}
        </Portfolios>
      </PortfolioWrapper>
    </>
  );
}

export default Portfolio;
