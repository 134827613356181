import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    black: "#2e2e35",
    dark: "#34343c",
    white: "#e9e9e9",
    grey: "#a6adaf",
    yellow: "#f7b141",
    yellow0: "#f0a835",
    yellow1: "#e3aa51",
    yellow2: "#deb13c",
    yellow3: "#e5ab4a",
  },
};

const Theme = ({ children }: any) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
