import React, { useEffect, useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/bundle";

import CssLogo from "../assets/img/css-logo.png";
import JsLogo from "../assets/img/javascript-logo.png";
import HtmlLogo from "../assets/img/html-logo.png";
import TsLogo from "../assets/img/typescript-logo.png";
import WpLogo from "../assets/img/wordpress-logo.png";
import ReactLogo from "../assets/img/react-logo.png";
import ReactLogo2 from "../assets/img/react-logo-2.png";
import SassLogo from "../assets/img/sass-logo.png";
import PsLogo from "../assets/img/ps-logo.png";
import AiLogo from "../assets/img/ai-logo.png";
import VscLogo from "../assets/img/vscode-logo.png";
import XdLogo from "../assets/img/xd-logo.png";
import FigmaLogo from "../assets/img/figma-logo.png";
import styled from "styled-components";
import { Container } from "../components/styled/Container.styled";

import gsap from "gsap";
import { Expo, Power4 } from "gsap/src";

const LogosRow = styled.div`
  padding: 10rem 0;
  background: ${(props) => props.theme.colors.dark};
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 9999;
`;

const Logo = styled.img`
  height: 100px;
  width: auto;
  filter: brightness(150%) grayscale(100%);
  /* filter: grayscale(100%); */
`;

const Title = styled.h2`
  /* align-self: flex-start; */
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5rem;
`;

export default function Icons() {
  const animationRef = useRef(null);
  const q = gsap.utils.selector(animationRef);
  useEffect(() => {
    gsap.from(".mySwiper", {
      left: "100%",
      opacity: 0,
      duration: 2,
      // ease: Power2.easeInOut,
      ease: Power4.easeOut,
      scrollTrigger: {
        trigger: ".mySwiper",
        start: "top 80%",
      },
    });
  }, []);
  return (
    <LogosRow>
      <Container ref={animationRef}>
        {/* <Title>Technologies I use</Title> */}
        <Swiper
          breakpoints={{
            1: {
              slidesPerView: 2,
            },
            350: {
              slidesPerView: 3,
            },
            800: {
              slidesPerView: 4,
            },
            1000: {
              slidesPerView: 5,
            },
            1200: {
              slidesPerView: 6,
            },
          }}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          pagination={{
            clickable: true,
          }}
          loop={true}
          modules={[Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <Logo src={JsLogo} alt="Javascript" />
          </SwiperSlide>
          <SwiperSlide>
            <Logo src={ReactLogo} alt="React" />
          </SwiperSlide>
          <SwiperSlide>
            <Logo src={SassLogo} alt="Sass" />
          </SwiperSlide>
          <SwiperSlide>
            <Logo src={HtmlLogo} alt="HTML" />
          </SwiperSlide>
          <SwiperSlide>
            <Logo src={CssLogo} alt="CSS" />
          </SwiperSlide>
          <SwiperSlide>
            <Logo src={TsLogo} alt="Typescript" />
          </SwiperSlide>
          <SwiperSlide>
            <Logo src={WpLogo} alt="Wordpress" />
          </SwiperSlide>
          <SwiperSlide>
            <Logo src={PsLogo} alt="Adobe Photoshop" />
          </SwiperSlide>
          <SwiperSlide>
            <Logo src={AiLogo} alt="Adobe Illustrator" />
          </SwiperSlide>
          <SwiperSlide>
            <Logo src={XdLogo} alt="Adobe Xd" />
          </SwiperSlide>
          <SwiperSlide>
            <Logo src={FigmaLogo} alt="Figma" />
          </SwiperSlide>
          <SwiperSlide>
            <Logo src={VscLogo} alt="VSCode" />
          </SwiperSlide>
        </Swiper>
      </Container>
    </LogosRow>
  );
}
