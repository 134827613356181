import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Container } from "../components/styled/Container.styled";
import { SCREENS } from "../assets/responsive";
import {
  BsBrushFill,
  BsCodeSlash,
  BsDisplayFill,
  BsGlobe2,
  BsFillGrid1X2Fill,
  BsPrinterFill,
} from "react-icons/bs";
import gsap from "gsap";
import { Expo, Power4 } from "gsap/src";
import ScrollTrigger from "gsap/ScrollTrigger";

const ServicesRow = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.dark};
  display: flex;
  color: ${(props) => props.theme.colors.white};
  justify-content: center;
  padding: 0 0 15rem 0;
`;

const Title = styled.h2`
  font-size: 50px;
  margin-bottom: 4rem;
  /* color: ${(props) => props.theme.colors.yellow}; */
`;

const ServiceCardsGroup = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
`;

const ServiceCard = styled.div`
  border-radius: 5px;
  padding: 4rem 2rem;
  background: ${(props) => props.theme.colors.black};
  box-shadow: 0px 0px 5px 0px rgba(114, 114, 114, 0.1);
  transition: background ease-in-out 0.33s;
  position: relative;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    content: "";
    background: ${(props) => props.theme.colors.yellow};
  }

  width: 100%;
  @media screen and (min-width: ${SCREENS.md}) {
    width: 48%;
  }
  @media screen and (min-width: ${SCREENS.xl}) {
    width: 32.2%;
  }
  &:hover {
    color: ${(props) => props.theme.colors.black};
    box-shadow: 0px 0px 10px 0px rgba(229, 171, 74, 0.5);
    background: ${(props) => props.theme.colors.yellow};
  }
`;

const CardHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const CardImg = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 15px;
  margin-bottom: 1.5rem;
`;

const CardTitle = styled.h3`
  font-size: 1.8rem;
  font-weight: 700;
`;

const CardDesc = styled.span`
  line-height: 1.7rem;
`;

const descContent = {
  webdev: {
    title: "Web Development",
    english:
      "Graphic design of websites. Coding the page in HTML, CSS, Javascript (React). Build an API and make integration with it.",
    polish:
      "Projektowanie graficzne stron Internetowych. Kodowanie strony w HTML, CSS, Javascript (React). Stworzenie API oraz integracja z nim.",
  },

  frontend: {
    title: "Front-End Development",
    english:
      "Transfrom graphic mockup into the fully functional website or web application.",
    polish:
      "Transformowanie projektu graficznego do w pełni funkcjonalnej aplikacji lub strony Internetowej.",
  },

  ux: {
    title: "User Experiance Design",
    english: "Graphic project designed specifically for the client's needs.",
    polish:
      "Stworzenie projektu graficznego zaprojektowanego specjalnie na potrzeby klienta.",
  },

  solana: {
    title: "Solana Web3.js Development",
    english:
      "Integration with Solana blockchain, the fastest growing ecosystem in crypto. NFT, Integration with Web3 (wallet integration, handling transactions, etc.).",
    polish:
      "Integracja z blockchainem Solany, najszybciej rozwijającym się ekosystemem w crypto świecie. Obsługa projektów NFT, Integracja z Web3 (obsługa portfela, transakcji, itp.) ",
  },

  logo: {
    title: "Logo Design",
    english:
      "Logo design in a modern and minimalist style. Using the Golden Ratio based on the Fibbonaci sequence.",
    polish:
      "Projektowanie loga w nowoczesnym i minimalistycznym stylu. Wykorzystywanie Złotej Proporcji opratej o ciąg Fibbonaciego.",
  },

  print: {
    title: "Print Design",
    english:
      "Projecting everything that is non-digital. Cards, posters, leaflets, printing on pens, T-shirts, letterhead, etc.",
    polish:
      "Porjektowanie wszystkiego co niecyfrowe. Wziytówki, plakaty, ulotki, nadruk na długopisy, koszulki, papier firmowy, etc.",
  },
};

export default function Services() {
  gsap.registerPlugin(ScrollTrigger);
  const animationRef = useRef(null);
  const q = gsap.utils.selector(animationRef);

  useEffect(() => {
    gsap.from(q(".animate-service"), {
      y: "100%",
      stagger: 0.25,
      opacity: 0,
      duration: 1.6,
      // ease: Power2.easeInOut,
      ease: Expo.easeOut,
      scrollTrigger: {
        trigger: ".animation-start",
        start: "top 70%",
      },
    });
  }, []);

  return (
    <ServicesRow ref={animationRef}>
      <Container center>
        <Title className="animation-start animate-service">My Services</Title>
        <ServiceCardsGroup>
          <ServiceCard className="animate-service">
            <BsCodeSlash fontSize={60} />
            <CardHeading>
              <CardTitle>{descContent.webdev.title}</CardTitle>
            </CardHeading>
            <CardDesc>{descContent.webdev.english}</CardDesc>
          </ServiceCard>
          <ServiceCard className="animate-service">
            <BsDisplayFill fontSize={60} />
            <CardHeading>
              <CardTitle>{descContent.frontend.title}</CardTitle>
            </CardHeading>
            <CardDesc>{descContent.frontend.english}</CardDesc>
          </ServiceCard>
          <ServiceCard className="animate-service">
            <BsFillGrid1X2Fill fontSize={60} />
            <CardHeading>
              <CardTitle>{descContent.ux.title}</CardTitle>
            </CardHeading>
            <CardDesc>{descContent.ux.english}</CardDesc>
          </ServiceCard>
          <ServiceCard className="animate-service">
            <BsGlobe2 fontSize={60} />
            <CardHeading>
              <CardTitle>{descContent.solana.title}</CardTitle>
            </CardHeading>
            <CardDesc>{descContent.solana.english}</CardDesc>
          </ServiceCard>
          <ServiceCard className="animate-service">
            <BsBrushFill fontSize={60} />
            <CardHeading>
              <CardTitle>{descContent.logo.title}</CardTitle>
            </CardHeading>
            <CardDesc>{descContent.logo.english}</CardDesc>
          </ServiceCard>
          <ServiceCard className="animate-service">
            <BsPrinterFill fontSize={60} />
            <CardHeading>
              <CardTitle>{descContent.print.title}</CardTitle>
            </CardHeading>
            <CardDesc>{descContent.print.english}</CardDesc>
          </ServiceCard>
        </ServiceCardsGroup>
      </Container>
    </ServicesRow>
  );
}
