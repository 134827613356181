import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SCREENS } from "../assets/responsive";
import { slide as MobileMenu } from "react-burger-menu";
import menuStyle from "./menuStyle";

const NavLink = styled.li`
  display: flex !important;
  flex-direction: column;
  margin: 0 15px;
  font-size: 25px;
  margin-top: 2rem;
  text-align: center;
  a {
    text-decoration: none;
    color: inherit;
    transition: 300ms all ease-in-out;
    font-weight: 600;
    &:hover {
      color: ${(props) => props.theme.colors.yellow};
    }
  }
  @media screen and (min-width: ${SCREENS.md}) {
    display: inline-flex !important;
    font-size: 17px;
    margin: 0 20px;
  }
  @media screen and (min-width: ${SCREENS.xl}) {
    font-size: 20px;
    margin: 0 25px;
  }
`;

function Menu() {
  const isMobile = useMediaQuery({
    maxWidth: SCREENS.md,
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  if (isMobile)
    return (
      <MobileMenu
        right
        styles={menuStyle}
        isOpen={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <NavLink className="animate mobile">
          <Link to="/" onClick={handleClose}>
            Home
          </Link>
        </NavLink>

        <NavLink className="animate mobile">
          <Link to="/portfolio" onClick={handleClose}>
            Portfolio
          </Link>
        </NavLink>
        <NavLink className="animate mobile">
          <Link to="/contact" onClick={handleClose}>
            Contact
          </Link>
        </NavLink>
      </MobileMenu>
    );
  return (
    <>
      <NavLink className="animate">
        <Link to="/">Home</Link>
      </NavLink>

      <NavLink className="animate">
        <Link to="/portfolio">Portfolio</Link>
      </NavLink>
      <NavLink className="animate">
        <Link to="/contact">Contact</Link>
      </NavLink>
    </>
  );
}

export default Menu;
