import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { IPortfolio, portfolioData } from "./portfolioData";
import gsap from "gsap";
import { Expo, Power4 } from "gsap/src";
import ScrollTrigger from "gsap/ScrollTrigger";

import {
  FaDiscord,
  FaGlobe,
  FaStore,
  FaStoreAlt,
  FaTwitter,
} from "react-icons/fa";
import styled from "styled-components";
import NavbarSticky from "../../layout/NavbarSticky";
import {
  PortfolioContainer,
  TopRow,
  LeftContainer,
  RightContainer,
  Spacer,
  Line,
  ProjectDescription,
  ProjectDetails,
  Row,
} from "../../components/styled/Portfolio.styled";

const CenteredContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  font-size: 4rem;
  font-weight: bold;
`;

function Single() {
  const [post, setPost] = useState<IPortfolio | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const location = useLocation();
  const itemsRef = useRef<Array<HTMLElement>>([]);
  itemsRef.current = [];
  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (item: any) => {
    if (item) {
      itemsRef.current.push(item);
    }
  };

  useEffect(() => {
    setPost(portfolioData.find((el) => el.id === params.id));
  }, [params]);

  const animateRef = useRef(null);
  const q = gsap.utils.selector(animateRef);
  useEffect(() => {
    gsap.from(q(".animate"), {
      y: "100%",
      stagger: 0.15,
      opacity: 0,
      duration: 1.6,

      // ease: Power2.easeInOut,
      ease: Expo.easeOut,
      scrollTrigger: {
        trigger: ".animate",
        start: "top bottom",
      },
    });

    itemsRef.current.map((item) => {
      gsap.from(item, {
        duration: 1.6,
        y: "100%",
        stagger: 0.2,
        opacity: 0,
        ease: Expo.easeOut,

        scrollTrigger: {
          trigger: item,
          start: "top bottom",
        },
      });
    });
  }, [post]);

  if (post) {
    return (
      <>
        <NavbarSticky />
        <PortfolioContainer ref={animateRef}>
          <TopRow>
            <LeftContainer>
              <h2 className="animate">{post?.id}</h2>
              <div className="socials">
                {post.website && (
                  <a href={post.website} target="_blank" className="animate">
                    <FaGlobe />
                  </a>
                )}
                {post.website2 && (
                  <a href={post.website2} target="_blank" className="animate">
                    <FaGlobe />
                  </a>
                )}
                {post.twitter && (
                  <a href={post.twitter} target="_blank" className="animate">
                    <FaTwitter />
                  </a>
                )}
                {post.discord && (
                  <a href={post.discord} target="_blank" className="animate">
                    <FaDiscord />
                  </a>
                )}
                {post.markets &&
                  post.markets.map((market) => (
                    <a href={market} target="_blank" className="animate">
                      <FaStoreAlt />
                    </a>
                  ))}
              </div>
            </LeftContainer>
            <RightContainer>
              <div className="content-container animate">
                <p className="title">MY SERVICES</p>
                <p className="content">
                  {post?.services.map((service) => (
                    <>
                      {service}
                      <br />{" "}
                    </>
                  ))}
                </p>
              </div>
              <div className="content-container animate">
                <p className="title ">TECHNOLOGIES</p>
                <p className="content ">
                  {post?.technologies.map((technologie) => (
                    <>
                      {technologie}
                      <br />{" "}
                    </>
                  ))}
                </p>
              </div>
              <div className="content-container full animate">
                <p className="title ">ABOUT PROJECT</p>
                <p className="content ">{post?.about}</p>
              </div>
            </RightContainer>
          </TopRow>
          <Spacer />
          <Row>
            <img
              src={post?.heroImg}
              alt={`${post?.id} showcase`}
              aria-disabled
              className="animate"
            />
          </Row>
          <Spacer />
          <Row>
            <ProjectDescription ref={addToRefs}>
              {post?.description}
            </ProjectDescription>
          </Row>
          <Line ref={addToRefs} />
          <Spacer />
          {post?.challange && (
            <>
              <Row>
                <ProjectDetails ref={addToRefs}>
                  <h3>Challange</h3>
                  <p>{post?.challange}</p>
                </ProjectDetails>
              </Row>
              <Spacer height="5rem" />
            </>
          )}

          {post?.solutions && (
            <>
              <Row>
                <ProjectDetails>
                  <h3 ref={addToRefs}>Solution</h3>
                  <ul>
                    {post?.solutions?.map((solution) => (
                      <li ref={addToRefs} key={solution}>
                        {solution}
                      </li>
                    ))}
                  </ul>
                </ProjectDetails>
              </Row>
              <Spacer height="5rem" />
            </>
          )}

          <Row>
            <ProjectDetails>
              {post?.images?.map((image, index) => (
                <>
                  <img key={index} src={image} />
                  <Spacer height="1rem" />
                </>
              ))}
            </ProjectDetails>
          </Row>
        </PortfolioContainer>
      </>
    );
  } else {
    return (
      <>
        <NavbarSticky />
        <CenteredContainer></CenteredContainer>
      </>
    );
  }
}

export default Single;
