import styled from "styled-components";
import { SCREENS } from "../../assets/responsive";

interface StyleProps {
  height?: string;
}

export const PortfolioContainer = styled.div`
  padding: 8rem 0;
  width: 100%;
  max-width: ${SCREENS.xl};

  @media screen and (max-width: ${SCREENS.xl}) {
    padding: 8rem 1rem;
  }
`;
export const Row = styled.div`
  display: flex;
  width: 100%;
  color: ${(props) => props.theme.colors.white};
  img {
    max-width: 100%;
    height: auto;
    box-shadow: 0px 0px 20px 4px rgba(26, 26, 26, 0.3);
  }
`;

export const TopRow = styled(Row)`
  flex-wrap: wrap;
  gap: 3rem 0;
`;

export const Spacer = styled.div<StyleProps>`
  display: flex;
  min-height: ${(props: StyleProps) =>
    props.height ? `${props.height}` : "8rem"};
`;

export const LeftContainer = styled.div`
  width: 100%;
  @media screen and (min-width: ${SCREENS.lg}) {
    width: 50%;
  }

  h2 {
    font-size: 3rem;
    margin-bottom: 4rem;
    line-height: 1.15em;
    max-width: 90%;
    @media screen and (min-width: ${SCREENS.lg}) {
      font-size: 5rem;
    }
  }
  .socials {
    display: flex;
    gap: 2rem;
    font-size: 3rem;
    a {
      color: inherit;
    }
    svg {
      border: 1px solid #4f4f4f;
      border-radius: 50%;
      padding: 10px;
      transition: 300ms all ease-in-out;

      &:hover {
        color: ${(props) => props.theme.colors.black};
        border: 1px solid ${(props) => props.theme.colors.yellow};
        background-color: ${(props) => props.theme.colors.yellow};
        cursor: pointer;
      }
    }
  }
`;
export const RightContainer = styled.div`
  display: flex;
  gap: 5rem 0;
  flex-wrap: wrap;
  width: 100%;
  @media screen and (min-width: ${SCREENS.lg}) {
    width: 50%;
  }
  .content-container {
    width: 100%;
    padding-right: 4rem;
    @media screen and (min-width: ${SCREENS.md}) {
      width: 50%;
    }
    &.full {
      width: 100%;
    }
    .title {
      color: ${(props) => props.theme.colors.grey};
      margin-bottom: 1.5rem;
      font-weight: 300;
    }
    .content {
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.6rem;
    }
  }
`;

export const ProjectDescription = styled.p`
  font-size: 1.8rem;
  line-height: 1.7em;
  font-weight: 500;
`;

export const ProjectDetails = styled.div`
  h3 {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  p,
  ul {
    font-size: 1.3rem;
    line-height: 1.3em;
  }
  ul {
    padding-left: 1rem;
    margin-top: 1.5rem;
    li {
      margin-bottom: 0.5rem;
    }
  }
`;

export const Line = styled.hr`
  margin-top: 5rem;
  border-color: ${(props) => props.theme.colors.grey};
`;
