import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { SCREENS } from "../assets/responsive";
import Menu from "../components/Menu";
import gsap from "gsap";
import { Expo, Power4 } from "gsap/src";
import { Link } from "react-router-dom";

const NavbarContainer = styled.nav`
  width: 100%;
  max-width: ${SCREENS.xxl};
  padding: 3.5rem 1rem;
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 9999;
`;

const LogoContainer = styled.div`
  font-weight: 700;
  font-size: 17px;
  color: ${(props) => props.theme.colors.black};
  z-index: 999;
  margin-right: 6.5rem;
  display: inline-flex;
  a {
    text-decoration: none;
    color: inherit;
  }
  @media screen and (min-width: ${SCREENS.md}) {
    margin-right: 13rem;
    font-size: 20px;
  }
  @media screen and (min-width: ${SCREENS.xl}) {
    margin-right: 15rem;
    font-size: 25px;
  }
`;

const LinksContainer = styled.ul`
  margin-top: auto;
  margin-bottom: auto;
  color: ${(props) => props.theme.colors.white};
`;

const RightContainer = styled.div`
  width: 30px;
`;

export default function Navbar() {
  const animationRef = useRef(null);
  const q = gsap.utils.selector(animationRef);
  useEffect(() => {
    gsap.from(q(".animate"), {
      y: "100%",
      stagger: 0.25,
      delay: 1,
      opacity: 0,
      duration: 1.6,
      // ease: Power2.easeInOut,
      ease: Expo.easeOut,
    });
  }, []);
  return (
    <NavbarContainer ref={animationRef}>
      <LogoContainer className="animate">
        <Link to="/">koralesky</Link>
      </LogoContainer>
      <LinksContainer>
        <Menu />
      </LinksContainer>
      <RightContainer />
    </NavbarContainer>
  );
}
