import styled from "styled-components";
import { SCREENS } from "../../assets/responsive";

interface IProps {
  center?: boolean;
}

export const Container = styled.div<IProps>`
  width: 100%;
  max-width: ${SCREENS.xxl};
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  align-items: ${(props) => (props.center ? "center" : "unset")};
`;
