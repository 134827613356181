import React from "react";
import styled from "styled-components";

interface IProps {
  bgcolor?: string;
  children: JSX.Element | JSX.Element[] | string | string[];
  center?: boolean;
}

const Row = styled.div<IProps>`
  width: 100%;
  background: ${(props) =>
    props.bgcolor === "dark"
      ? props.theme.colors.dark
      : props.bgcolor === "black"
      ? props.theme.colors.black
      : props.bgcolor
      ? props.bgcolor
      : props.theme.colors.dark};
  display: flex;
  color: ${(props) => props.theme.colors.white};
  justify-content: ${(props) => (props.center ? "center" : "unset")};
  padding: 7rem 1rem 7rem 1rem;
`;

export default function Wrapper(props: IProps) {
  return (
    <Row center={props.center} bgcolor={props.bgcolor}>
      {props.children}
    </Row>
  );
}
